// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password/:token';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const ORDER_FORM = '/order';
export const ORDER_FORM_MODIFY = '/order-modify/:id';
export const SALE_LOCATIONS = '/sale-locations';
export const CUSTOMERS = '/customers';
export const MONTHS = '/months';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const LABELS = '/labels';
export const LABEL_PDF = '/label/:id';
export const ORDER_PDF = '/order-pdf/:id';
export const ORDERS_PDF = '/orders-pdf/:monthId/:saleLocationId';
export const LOTS = '/lots';
export const ORDERED_ARTICLES = '/ordered-articles';
export const ORDERED_ARTICLES_PDF = '/ordered-articles-pdf';

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '€';

export const AUTH_TOKEN = 'auth-token';

export const PRODUCT = 'Product';
export const LOT = 'Lot';

