import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PRODUCTS,
  CATEGORY,
  DASHBOARD,
  ORDERS,
  ORDER_FORM,
  ORDER_FORM_MODIFY,
  SALE_LOCATIONS,
  SETTINGS,
  CUSTOMERS,
  MONTHS,
  COUPONS,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  LABELS,
  LABEL_PDF,
  ORDER_PDF,
  ORDERS_PDF,
  LOTS,
  ORDERED_ARTICLES,
  ORDERED_ARTICLES_PDF,
} from 'settings/constants';
import AuthProvider, { AuthContext } from 'context/auth';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';

const Products = lazy(() => import('containers/Products/Products'));
const AdminLayout = lazy(() => import('containers/Layout/Layout'));
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'));
const Category = lazy(() => import('containers/Category/Category'));
const Orders = lazy(() => import('containers/Orders/Orders'));
const OrderForm = lazy(() => import('containers/OrderForm/OrderForm'));
const OrderFormModify = lazy(() => import('containers/OrderForm/OrderFormModify'));
const SaleLocations = lazy(() => import('containers/SaleLocations/SaleLocations'));
const Labels = lazy(() => import('containers/Labels/Labels'));
const ProductLabelPdf = lazy(() => import('containers/Pdf/ProductLabelPdf.js'));
const OrderPdf = lazy(() => import('containers/Pdf/OrderPdf.js'));
const SelectedOrdersPdf = lazy(() => import('containers/Pdf/SelectedOrdersPdf.js'));
const OrderedArticlesPdf = lazy(() => import('containers/Pdf/OrderedArticlesPdf.js'));
const Lots = lazy(() => import('containers/Lots/Lots'));
const OrderedArticles = lazy(() => import('containers/OrderedArticles/OrderedArticles'));

const Settings = lazy(() => import('containers/Settings/Settings'));
const SiteSettingForm = lazy(() =>
  import('containers/SiteSettingForm/SiteSettingForm')
);
const StaffMembers = lazy(() => import('containers/StaffMembers/StaffMembers'));
const Customers = lazy(() => import('containers/Customers/Customers'));
const Months = lazy(() => import('containers/Months/Months'));
const Coupons = lazy(() => import('containers/Coupons/Coupons'));
const Login = lazy(() => import('containers/Login/Login'));
const ForgotPassword = lazy(() => import('containers/Login/ForgotPassword'));
const ResetPassword = lazy(() => import('containers/Login/ResetPassword'));
const NotFound = lazy(() => import('containers/NotFound/NotFound'));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated, checkToken } = useContext(AuthContext);

  checkToken()
  return (
    <Route
      {...rest}
      render={({ location }) =>{
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }}
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LOTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Lots />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDER_FORM}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OrderForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDER_FORM_MODIFY}>

            <AdminLayout >
              <Suspense fallback={<InLineLoader />}>
                <OrderFormModify />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SALE_LOCATIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SaleLocations />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MONTHS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Months />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERED_ARTICLES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OrderedArticles />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LABELS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Labels />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LABEL_PDF}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductLabelPdf />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDER_PDF}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OrderPdf />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERED_ARTICLES_PDF}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OrderedArticlesPdf />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS_PDF}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SelectedOrdersPdf />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={FORGOT_PASSWORD}>
            <ForgotPassword />
          </Route>
          <Route path={RESET_PASSWORD}>
            <ResetPassword />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
