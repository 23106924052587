import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { AUTH_TOKEN } from 'settings/constants';
import { theme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';


const httpLink = new createUploadLink({ uri: process.env.REACT_APP_API_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  return forward(operation);
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors[0].message === 'UNAUTHENTICATED') {
    localStorage.removeItem(AUTH_TOKEN);
    client.cache.reset()
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authMiddleware.concat(httpLink)),
  // uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

function App() {
  const engine = new Styletron();
  return (
    
    <BrowserRouter>
      <ApolloProvider client={client as any}>
        <StyletronProvider value={engine}>
          <BaseProvider theme={theme}>

            <Routes />
          </BaseProvider>
        </StyletronProvider>
      </ApolloProvider>
    </BrowserRouter>

  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
