import React from 'react';
import { AUTH_TOKEN } from 'settings/constants';
type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  checkToken: Function;
  signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = (props: any) => {

  const isValidToken = () => {
    const token = localStorage.getItem(AUTH_TOKEN);
    // JWT decode & check token validity & expiration.
    if (token) return true;
    return false;
  };
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken);
  function authenticate(token,cb) {
    makeAuthenticated(true);
    localStorage.setItem(AUTH_TOKEN, token.substring(4));
    setTimeout(cb, 100); // fake async
  }
  function signout(cb) {
    makeAuthenticated(false);
    localStorage.removeItem(AUTH_TOKEN);
    setTimeout(cb, 100);
  }
  function checkToken(cb) {
    makeAuthenticated(isValidToken);
    // localStorage.removeItem(AUTH_TOKEN);
    // setTimeout(cb, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        checkToken,
        signout,
      }}
    >
      <>{props.children}</> 
    </AuthContext.Provider>
  );
};

export default AuthProvider;
